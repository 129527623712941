<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!--      <a-form-model-item label="店铺id 外键shop_shop.id" prop="shopId" >
              <a-input v-model="form.shopId" placeholder="请输入店铺id 外键shop_shop.id" />
            </a-form-model-item>-->
      <!--      <a-form-model-item label="优惠券名称" prop="couponName" >-->
      <!--        <a-input v-model="form.couponName" placeholder="请输入优惠券名称" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="优惠金额 单位(元)" prop="couponAmount">
        <a-input-number v-model="form.couponAmount" placeholder="优惠金额"/>
      </a-form-model-item>
      <a-form-model-item label="满xx可用 单位(元)(无门槛不给值)" prop="couponCondition">
        <a-input-number v-model="form.couponCondition" placeholder="满xx可用"/>
      </a-form-model-item>
      <a-form-model-item label="适用时间 1:工作日,2:节假日" prop="enableTime">
        <a-input-number v-model="form.enableTime" placeholder="适用时间"/>
      </a-form-model-item>
      <a-form-model-item label="可用有效期" prop="validDay">
        <a-input v-model="form.validDay" placeholder="请输入可用有效期"/>
      </a-form-model-item>
      <a-form-model-item label="总发放数量" prop="totalNum">
        <a-input-number v-model="form.totalNum" placeholder="总发放数量"/>
      </a-form-model-item>
      <!--      <a-form-model-item label="剩余发放数量" prop="remainNum" >
              <a-input v-model="form.remainNum" placeholder="请输入剩余发放数量" />
            </a-form-model-item>-->
      <!--      <a-form-model-item label="是否暂停发放 1:暂停,0:继续" prop="isPause" >
              <a-input v-model="form.isPause" placeholder="请输入是否暂停发放 1:暂停,0:继续" />
            </a-form-model-item>-->
      <a-form-model-item label="使用规则" prop="rule">
        <a-input v-model="form.rule" placeholder="请输入内容" type="textarea" allow-clear/>
      </a-form-model-item>

      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear/>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getCoupon, addCoupon, updateCoupon} from '@/api/coupon/coupon'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        shopId: null,

        couponName: null,

        couponAmount: null,

        couponCondition: null,

        enableTime: null,

        validDay: null,

        totalNum: null,

        remainNum: null,

        isPause: null,

        rule: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        shopId: [
          {required: true, message: '店铺id 外键shop_shop.id不能为空', trigger: 'blur'}
        ],

        couponName: [
          {required: true, message: '优惠券名称不能为空', trigger: 'blur'}
        ],

        couponAmount: [
          {required: true, message: '优惠金额 单位(元)不能为空', trigger: 'blur'}
        ],

        /*couponCondition: [
          { required: true, message: '满xx可用 单位(元)不能为空', trigger: 'blur' }
        ],*/

        enableTime: [
          {required: true, message: '适用时间 1:工作日,2:节假日不能为空', trigger: 'blur'}
        ],

        validDay: [
          {required: true, message: '可用有效期不能为空', trigger: 'blur'}
        ],

        totalNum: [
          {required: true, message: '总发放数量不能为空', trigger: 'blur'}
        ],

        remainNum: [
          {required: true, message: '剩余发放数量不能为空', trigger: 'blur'}
        ],

        isDeleted: [
          {required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur'}
        ],

        version: [
          {required: true, message: '乐观锁不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,

        shopId: null,

        couponName: null,

        couponAmount: null,

        couponCondition: null,

        enableTime: null,

        validDay: null,

        totalNum: null,

        remainNum: null,

        isPause: null,

        rule: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCoupon(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCoupon(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCoupon(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
